import React from 'react'

function TravelUpdates() {
   return (
      <div >
         {/* style={{ background: 'linear-gradient(to right, #54d2e0, #0857b1)' }}> */}
         TravelUpdates
      </div>
   )
}

export default TravelUpdates