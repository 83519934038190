import About from "../pages/About";
import Policies from "../pages/Policies";
import Service from "../pages/Service";
import Welcome from "../pages/Welcome";
import RefundPolicy from "../pages/RefundPolicy";
import Contact from "../pages/Contact";
import TermAndCondition from "../pages/TermConditions";
import Tourist from "../pages/Tourist";
import Business from "../pages/Business";
import Instruction from "../pages/Instruction";


export const TouristOptions = ["M.I.C.E Tourist(Meetings, Incentives, Conference & Exhibitions / Events)",
   "Medical treatment including Ayurvedic(herbal)",
   "Participate in Art, Music, and Dance Events",
   "Participate in Pilgrimages",
   "Participate in Sport Events",
   "Participate in Weddings",
   "Visiting Friends and Relatives",
   "Sightseeing or Holidaying"
]

export const TouristVisAndTime = {
   "Tourist_Visa_30_Days": "Tourist Visa 30 Days",
}

export const BusinessVisaAndTime = {
   "Standard_Visitor_Visa_6_Months_Each_Stay_Resticated_Upto_60_Days_bussiness": "Standard Business Visa(6 months) each stay restricted up to 60 days",
   "one_Year_Multiple_Entry_business_Visa_Each_Stay_Resticated_Upto_90_Days": "1 Year Multiple Entry Business Visa each stay restricted upto 90 days",
   "two_Years_Multiple_Entry_business_Visa_Each_Stay_Resticated_Upto_180_Days": "2 Years Multiple Entry Business Visa each stay restricted upto 180 days"
}

export const VisaInformationList = [
   { name: "e-Visa Sri Lanka", path: "/", component: <Welcome /> },
   { name: "Tourist e-Visa", path: "/tourist-e-visa", component: <Tourist /> },
   { name: "Business e-Visa", path: "/business-e-visa", component: <Business /> },
   { name: "Instructions for Applicant", path: "/instructions", component: <Instruction /> },
   { name: "Services", path: "/services", component: <Service /> },
   { name: "Eligible Countries", path: "/eligible-country" },
   { name: "About Us", path: "/about", component: <About /> },
   { name: "Privacy & Policy", path: "/privacy-policy", component: <Policies /> },
   { name: "Terms & Condition", path: "/term-and-condition", component: <TermAndCondition /> },
   { name: "Refund Policy", path: "/refund-policy", component: <RefundPolicy /> },
   { name: "Contact Us", path: "/contact", component: <Contact /> }
];



export const CountryName = [
   "Select Country",
   "Aland Islands",
   "Afghanistan",
   "Albania",
   "Algeria",
   "Angola",
   "American Samoa",
   "Andorra",
   "Anguilla",
   "Antarctica",
   "Antigua and Barbuda",
   "Argentina",
   "Armenia",
   "Aruba",
   "Australia",
   "Austria",
   "Azerbaijan",
   "Bahamas",
   "Bangladesh",
   "Bahrain",
   "Barbados",
   "Bhutan",
   "Belarus",
   "Belgium",
   "Belize",
   "Benin",
   "Bermuda",
   "Bolivia",
   "Bonaire Saint Eustatius and Saba",
   "Bosnia and Herzegovina",
   "Botswana",
   "Bouvet Island",
   "Brazil",
   "Brunei",
   "Bulgaria",
   "Burkina Faso",
   "Burundi",
   "Cambodia",
   "Canada",
   "Cape Verde",
   "Cayman Islands",
   "Central African Republic",
   "Chad",
   "Chile",
   "China",
   "Christmas Island",
   "Cocos Islands",
   "Colombia",
   "Comoros",
   "Republic of the Congo",
   "Democratic Republic of the Congo",
   "Cook Islands",
   "Costa Rica",
   "Croatia",
   "Cuba",
   "Curacao",
   "Cyprus",
   "Czech Republic",
   "Denmark",
   "Djibouti",
   "Dominica",
   "Dominican Republic",
   "Ecuador",
   "Egypt",
   "El Salvador",
   "Equatorial Guinea",
   "Eritrea",
   "Estonia",
   "Ethiopia",
   "Falkland Islands",
   "Faroe Islands",
   "Fiji",
   "Finland",
   "France",
   "France Metropolitan",
   "French Guiana",
   "French Polynesia",
   "French Southern",
   "Gabon",
   "Gambia",
   "Georgia",
   "Germany",
   "Gibraltar",
   "Greece",
   "Greenland",
   "Grenada",
   "Guadeloupe",
   "Guam",
   "Guatemala",
   "Guernsey",
   "Guinea",
   "Guinea-Bissau",
   "Guyana",
   "Haiti",
   "Heard Island and Mcdonald Islands",
   "Honduras",
   "Hong Kong",
   "Hungary",
   "Iceland",
   "Indonesia",
   "India",
   "Iran",
   "Iraq",
   "Ireland",
   "Isle Of Man",
   "Israel",
   "Italy",
   "Jamaica",
   "Japan",
   "Jersey",
   "Jordan",
   "Kazakhstan",
   "Kenya",
   "Kiribati",
   "Kosovo",
   "Kuwait",
   "Kyrgyzstan",
   "Laos",
   "Latvia",
   "Lebanon",
   "Lesotho",
   "Liberia",
   "Libya",
   "Liechtenstein",
   "Lithuania",
   "Luxembourg",
   "Macao",
   "Macedonia",
   "Madagascar",
   "Malawi",
   "Malaysia",
   "Mali",
   "Malta",
   "Marshall Islands",
   "Martinique",
   "Mauritania",
   "Mauritius",
   "Mayotte",
   "Mexico",
   "Micronesia",
   "Moldova",
   "Monaco",
   "Mongolia",
   "Montenegro",
   "Montserrat",
   "Morocco",
   "Mozambique",
   "Myanmar",
   "Namibia",
   "Nauru",
   "Netherlands",
   "Netherlands Antilles",
   "New Caledonia",
   "New Zealand",
   "Nepal",
   "Nicaragua",
   "Niger",
   "Niue",
   "Norfolk Island",
   "North Korea",
   "Northern Mariana",
   "Norway",
   "Oman",
   "Palau",
   "Palestinian Territory",
   "Panama",
   "Papua New Guinea",
   "Paraguay",
   "Peru",
   "Philippines",
   "Pitcairn",
   "Pakistan",
   "Poland",
   "Portugal",
   "Puerto Rico",
   "Qatar",
   "Reunion",
   "Romania",
   "Russia",
   "Rwanda",
   "Saint Barthelemy",
   "Saint Helena",
   "Saint Kitts and Nevis",
   "Saint Lucia",
   "Saint Martin",
   "Saint Pierre and Miquelon",
   "Saint Vincent and The Grenadines",
   "Samoa",
   "San Marino",
   "Sao Tome and Principe",
   "Saudi Arabia",
   "Senegal",
   "Serbia",
   "Sierra Leone",
   "Sint Maarten",
   "Slovakia",
   "Slovenia",
   "Solomon Islands",
   "Somalia",
   "South Africa",
   "South Georgia and The South Sandwich Islands",
   "South Korea",
   "South Sudan",
   "Spain",
   "Sudan",
   "Suriname",
   "Svalbard and Jan Mayen",
   "Swaziland",
   "Sweden",
   "Switzerland",
   "Taiwan",
   "Tajikistan",
   "Tanzania",
   "Thailand",
   "East Timor",
   "Togo",
   "Tokelau",
   "Tonga",
   "Trinidad and Tobago",
   "Tunisia",
   "Turkey",
   "Turkmenistan",
   "Turks and Caicos Islands",
   "Tuvalu",
   "Uganda",
   "Uk - British Dependant Territory Citizen",
   "Uk - British Indian Ocean Territory",
   "Uk - British National (Overseas)",
   "Uk - British Overseas Citizen",
   "Uk - British Protected Person",
   "Uk - British Subject",
   "Uk - British-Citizen (Great Britain)",
   "Ukraine",
   "United Arab Emirates",
   "United States",
   "United States Minor Outlying Islands",
   "Uruguay",
   "Uzbekistan",
   "Vanuatu",
   "Vatican",
   "Venezuela",
   "Vietnam",
   "British Virgin Islands",
   "U.S. Virgin Islands",
   "Wallis and Futuna",
   "Western Sahara",
   "Yemen",
   "Zaire",
   "Zambia",
   "Zimbabwe"
];
