import React from 'react'
import { Container } from 'react-bootstrap'

function Business() {
   return (
      <>
         <Container>
            <div className="page-title"><h2>Business eVisa for Sri Lanka</h2></div>
            <div className="page-main-container">
               <p>The <b>Sri Lanka Business eVisa</b>, also known as the <b>Electronic Travel Authorization (ETA) </b>
                  for Business, is an  visa system designed to facilitate the entry of foreign
                  nationals into Sri Lanka for business-related activities. This eVisa allows business
                  travelers to bypass the traditional paper visa application process by applying  ,
                  making the process quicker and more convenient.
               </p>
               <p className="page-sub-title"><b>Key Features of the Sri Lanka Business eVisa</b></p>

               <ol className='order-list'>
                  <li><b>Purpose of Visit:</b>  The Business eVisa is specifically intended for
                     individuals visiting Sri Lanka for business-related purposes. This includes attending
                     business meetings, conferences, workshops, seminars, or other professional activities.
                     It is not intended for employment, long-term residence, or engaging in work for which
                     compensation is provided by an entity within Sri Lanka.</li>
                  <li><b>Eligibility:</b> Most foreign nationals are eligible to apply for the Sri Lanka
                     Business eVisa. However, certain countries may have specific requirements or restrictions,
                     and it is recommended to check the latest eligibility criteria before applying. Some
                     nationalities might need to apply through a Sri Lankan diplomatic mission rather than using
                     the   eVisa system.</li>
                  <li><b>Application Process:</b> The application for the Sri Lanka Business eVisa is completed
                       through authorized visa service providers. Applicants are required to fill out a form
                     with personal information, passport details, and information about their business trip, such
                     as the purpose of the visit and the host organization's details.</li>
                  {/* sub options */}
                  <li>
                     <b>Required Documents:</b>
                     <ul className='order-list-sub-content'>
                        <li>A valid passport with at least six months of validity from the date of arrival in Sri Lanka.</li>
                        <li>A recent passport-sized photograph.</li>
                        <li>An invitation letter from the host organization in Sri Lanka, if applicable.</li>
                        <li>A credit or debit card to pay the eVisa processing fee.</li>
                     </ul>
                  </li>
                  <li><b>Processing Time:</b> The processing time for the Sri Lanka Business eVisa typically
                     ranges from 24 to 72 hours. However, it is advised to apply well in advance of the intended
                     travel date to allow for any unforeseen delays. Once the eVisa is approved, it is electronically
                     linked to the applicant’s passport, and a confirmation email is sent.</li>
                  <li><b>Validity and Duration: </b>The Sri Lanka Business eVisa is usually valid for six months from
                     the date of issue, allowing for a stay of up to 30 days per visit. It can be issued as a
                     single-entry or multiple-entry visa, depending on the applicant's needs. If a longer stay is
                     necessary, extensions may be possible by applying to the Department of Immigration and Emigration in Sri Lanka.</li>
                  <li><b>Visa Fee:</b> The visa fee of the Sri Lanka Business eVisa varies based on the applicant’s
                     nationality and whether the visa is single-entry or multiple-entry. Additional service
                     fees may apply if the application is made through a visa service provider.</li>
                  <li><b>On Arrival:</b> Upon arrival in Sri Lanka, business travelers must present
                     their passport and a copy of their eVisa approval (either printed or digital) to the
                     immigration authorities. The immigration officer may also request additional
                     documentation, such as an invitation letter from the host organization.</li>
                  <li><b>Extensions and Overstays:</b>  If business activities require an extended stay
                     beyond the initial 30 days, travelers can apply for an extension at the Department of
                     Immigration and Emigration in Sri Lanka. It is crucial to apply for an extension before
                     the visa expires to avoid fines or legal complications.</li>
               </ol>
               <p className='page-sub-title'><b>Conclusion</b></p>
               <p>The Sri Lanka Business eVisa simplifies the process for foreign nationals to engage in
                  business activities in Sri Lanka. By enabling travelers to apply  , the eVisa
                  reduces the time and effort involved in securing a visa. Business professionals can
                  focus on their work without worrying about the complexities of traditional visa
                  applications. However, it is important to adhere to the visa conditions, such as the
                  duration of stay and the purpose of the visit, to ensure a smooth and productive trip
                  to Sri Lanka.</p>
            </div>
         </Container>
      </>
   )
}

export default Business