import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import { Button, Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams, useNavigate } from "react-router-dom";
import { api, baseURL } from "../utils/api";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
function Payment() {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const fetchApplicationDetail = useCallback(async () => {
    if (!id) return;
    try {
      const response = await api.get(`/api/srilanka/View-application/${id}`);
      if (response.data && response.data.status) {
        setFormData(response.data.data);
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong");
    }
  }, [id]);

  useEffect(() => {
    fetchApplicationDetail();
  }, [fetchApplicationDetail]);

  let paymentAmount = 0;
  if (formData?.visaType === "BUSINESS VISA") {
    paymentAmount = 95;
  } else if (formData?.visaType === "TOURIST VISA") {
    paymentAmount = 85;
  }
  // ====================================================

  const getAccessToken = async () => {
    try {
      const response = await api.get("/api/srilanka/getaccesstocken");
      if (response.data?.accessToken) {
        setAccessToken(response.data.accessToken);
      } else {
        throw new Error("Access token not found in response.");
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
      alert("Failed to fetch payment configuration. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAccessToken();
  }, []);

  const createOrder = async () => {
    try {
      const response = await api.post(
        "/api/srilanka/create-Order",
        {
          amount: paymentAmount,
          currency: "USD",
          description: " visa Payment",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data?.id) {
        return response.data.id;
      } else {
        throw new Error("Order creation failed. No order ID returned.");
      }
    } catch (error) {
      console.error("Error creating order:", error);
      alert("Failed to create order. Please try again.");
      throw error;
    }
  };

  const onApprove = async (data) => {
    try {
      const response = await api.post(
        "/api/srilanka/capture-Payment",
        {
          orderID: data.orderID,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      alert("Payment captured successfully!");
      navigate(`/`);
    } catch (error) {
      console.error("Payment capture failed:", error.message || error);
      alert(
        `Payment capture failed. ${
          error.response?.data?.message || error.message || "Please try again."
        }`
      );
    }
  };
  const amount = paymentAmount * 100;
  const currency = "USD";
  const receiptId = "VISA00765";
  const paymentHandler = async (e) => {
    const response = await fetch(`${baseURL}/api/srilanka/order`, {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const order = await response.json();
    // console.log(order);

    var options = {
      key: "rzp_live_GiQR9cB7jSqyCR", // Enter the Key ID generated from the Dashboard
      // key: "rzp_test_0twaP547WNOmnJ", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: formData.surname, //your business name
      description: "Visa fee for srilanka",
      image: "https://example.com/your_logo",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateRes = await fetch(
          `${baseURL}/api/srilanka/order/validate`,
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonRes = await validateRes.json();
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: formData.surname, //your customer's name
        email: formData.email,
        contact: formData.mobile_number, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: formData.address_line1,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      console.log("response", response);
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  return (
    <>
      {formData && (
        <div className="gsrilanka">
          <Container className="pagesa4">
            <div className="j">
              <h3 className="welcomeText">Srilanka eVisa Fee</h3>
            </div>
            <hr />

            <div className="centerCol">
              <div className="welcomeText">
                <Container className="pagesa4">
                  <div className="paydiv">
                    <p> Application Number : {formData.sku_id}</p>
                    {/* <p> Fee : {paymentAmount} USD</p> */}
                    <p> Fee : {paymentAmount} USD</p>
                  </div>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={2} style={{ width: "300px" }}>
                      <Button
                        onClick={paymentHandler}
                        style={{ width: "100%", marginBottom: "10px" }}
                      >
                        {" "}
                        Razorpay{" "}
                      </Button>

                      <PayPalScriptProvider
                        options={{
                          "client-id":
                            "AX-FD8HqHo4a1TQZv54jLTYvitPnIAE7p5I5dp1PWch1RpVSOZFbKQKBpByKB9cE0gs_z5EoEkj-EKP9",
                          currency: "USD",
                        }}
                      >
                        <div className="paybutton">
                          {accessToken ? (
                            <PayPalButtons
                              createOrder={createOrder}
                              onApprove={async (data) => {
                                return onApprove(data);
                              }}
                              style={{ layout: "vertical" }}
                            />
                          ) : (
                            <div>Loading PayPal Buttons...</div>
                          )}
                        </div>
                      </PayPalScriptProvider>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default Payment;
