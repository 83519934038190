import React from 'react'
import { Container } from 'react-bootstrap'

function Instruction() {
   return (
      <>
         <Container>
            <div className="page-title"><h2>Instructions for Applicant</h2></div>
            <div className="page-main-container">
               <ol className='order-list'>
                  <li><b>Eligibility Check:</b> Ensure that you are eligible for the Sri Lanka eVisa (ETA) based on your nationality and the purpose of your visit (Tourism, Business, or Transit).</li>
                  <li><b> Visit the Official ETA Website:</b> Go to the Sri Lankan ETA portal or use an authorized visa service provider.</li>
                  <li><b> Complete the Application:</b> Fill out the application form with accurate personal details, passport information, and travel plans. Choose the correct visa type (Tourist, Business, or Transit).</li>
                  <li><b> Required Documents:</b> Prepare the following:
                     <ul className='order-list-sub-content'>
                        <li> Valid passport (minimum six months validity from the date of arrival)</li>
                        <li>Recent passport-sized photograph.</li>
                        <li>Proof of onward or return travel.</li>
                        <li>Invitation letter (for Business eVisa).</li>
                        <li>Sufficient funds evidence (if required).</li>
                     </ul>
                  </li>
                  <li><b> Payment:</b> Pay the eVisa processing fee using a valid credit or debit card. Fees vary depending on nationality and visa type.</li>
                  <li><b>Submit the Application:</b> Review all details carefully before submitting the application.</li>
                  <li><b>Receive Confirmation:</b> Upon approval, you will receive the eVisa via email. Print a copy or save it on your mobile device.</li>
               </ol>
            </div>
         </Container>
      </>
   )
}

export default Instruction