import React from 'react'
import { Container } from "react-bootstrap";

function Tourist() {
   return (
      <>
         <Container>
            <div className="page-title"><h2> Tourist eVisa for Sri Lanka</h2></div>
            <div className="page-main-container">
               <p>The <b>Sri Lanka Tourist eVisa</b>, also known as the <b>Electronic Travel Authorization (ETA)</b>, is an
                  official online authorization that allows foreign nationals to enter <b>Sri Lanka for tourism Purposes</b>.
                  Introduced by the Sri Lankan  to streamline the visa application process,
                  the eVisa replaces the need for a traditional paper visa and is designed to be a quicker and
                  more convenient way for tourists to gain entry into the country.
               </p>
               <p className="page-sub-title"><b>Key Features of the Sri Lanka Tourist eVisa</b></p>

               <ol className='order-list'>
                  <li><b>Purpose of Visit:</b> The Tourist eVisa is intended solely for tourism-related
                     activities. This includes activities such as sightseeing, visiting friends or relatives,
                     medical treatment, and participating in sporting or cultural events. The eVisa does not
                     permit work, study, or any other form of long-term stay.</li>
                  <li><b>Eligibility:</b> Most foreign nationals are eligible to apply for the Sri Lanka
                     Tourist eVisa. However, there are a few exceptions, particularly for citizens of
                     countries that have visa-exempt agreements with Sri Lanka or for those who are
                     required to obtain a visa through a Sri Lankan diplomatic mission. It is important
                     to check the latest eligibility criteria before applying.</li>
                  <li><b>Application Process:</b> The application for the Sri Lanka Tourist eVisa is
                     done entirely online through  authorized visa service providers. The process typically
                     involves filling out a simple form with personal details, passport information, and
                     travel plans. Applicants must also provide an email address, as the eVisa approval and
                     other communications are sent via email.</li>
                  {/* sub options */}
                  <li>
                     <b>Required Documents:</b>
                     <ul className='order-list-sub-content'>
                        <li>A valid passport with at least six months of validity from the date of arrival in Sri Lanka.</li>
                        <li>A recent passport-sized photograph.</li>
                        <li>A recent passport-sized photograph.</li>
                        <li>Proof of onward or return travel.</li>
                        <li>Proof of sufficient funds to cover the stay in Sri Lanka.</li>
                        <li> A credit or debit card to pay the eVisa processing fee.</li>
                     </ul>
                  </li>
                  <li><b>Processing Time:</b> The Sri Lanka Tourist eVisa is usually processed within 24
                     to 72 hours, though it is recommended to apply well in advance of travel to allow
                     for any delays. Once approved, the ETA is electronically linked to the applicant’s
                     passport, and a confirmation email is sent.</li>
                  <li><b>Validity and Duration: </b>The Sri Lanka Tourist eVisa is typically valid for
                     six months from the date of issuance, allowing for a stay of up to 30 days per visit.
                     The eVisa can be used for multiple entries, meaning travelers can enter Sri Lanka
                     more than once during the visa's validity period. If necessary, the 30-day stay can
                     be extended by visiting the Department of Immigration and Emigration in Sri Lanka.</li>
                  <li><b>Visa Fee:</b> The visa fee of the Sri Lanka Tourist eVisa varies depending on
                     the applicant’s nationality and the type of visa required (single-entry or multiple-entry).
                     There may be additional fees if the application is made through a visa service provider.</li>
                  <li><b>On Arrival:</b> Upon arrival in Sri Lanka, travelers must present their passport and
                     a copy of their eVisa approval (either printed or on a mobile device) to the immigration
                     authorities. It is important to ensure that the information on the eVisa matches the
                     passport details to avoid any entry issues.</li>
                  <li><b>Extensions and Overstays:</b> Travelers wishing to extend their stay beyond the
                     initial 30 days can apply for an extension at the Department of Immigration and
                     Emigration in Sri Lanka. Overstaying without an approved extension can result in fines or other penalties.</li>
               </ol>
               <p className='page-sub-title'><b>Conclusion</b></p>
               <p>The Sri Lanka Tourist eVisa is a convenient option for those wishing to visit this
                  beautiful island nation for leisure or tourism. The online application process is
                  straightforward, and the eVisa offers flexibility with its multiple-entry option and
                  the possibility of extending the stay. However, travelers should ensure they meet all
                  requirements and follow the visa conditions to avoid any complications during their
                  visit. The introduction of the eVisa system has made it easier than ever to explore
                  the rich cultural heritage, stunning landscapes, and vibrant cities of Sri Lanka.</p>
            </div>
         </Container>
      </>
   )
}

export default Tourist