import { React } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
import "../App.css"
function TermAndCondition() {
  return (
    <>
  <Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">

          <div className='page-title'>
          <h2>Terms &amp; Conditions  </h2>
        </div>

        <div className="page-main-container">
          <p> I hereby certify that I have read and understood all the terms and conditions set forth in this
            application and all the information I have furnished in this form are true and accurate to the best of
            my knowledge and belief.</p>
          <p> I understand that any false or misleading statement may result in the permanent refusal of an eVisa
            or denial of entry into Sri Lanka. I understand that possession of an eVisa does not entitle me to
            enter Sri Lanka upon arrival at a port of entry if I am found inadmissible.</p>

          <p className='page-sub-title'> <b> 1. All eVisa holders should be able to prove</b></p>
          <ul className='sub-order-list'>
            <li> A round trip ticket to show at the port of entry in Sri Lanka (only if you are traveling by air).</li>
            <li> Evidence of sufficient funds to cover your expenses in Sri Lanka. </li>
          </ul>
          <p className='page-sub-title'> <b> 2. Employment</b></p>
          <ul>
            <li> Holder of eVisa, should not engage in any form of employment, paid or unpaid, or
              in any trade or business other than specified in the eVisa, during the period of his/her stay in Sri
              Lanka</li>
          </ul>

          <p className='page-sub-title'> <b>3. Fees and Payments</b></p>
          <ul>
            <li> You agree that your credit card(s) will be billed immediately after
              submission of your application. Requirements and Fees related to processing of your application are
              subject to change from time to time and you agree that the eVisa processing fee once paid by you is
              not refundable and not transferable..</li>
          </ul>
          <p className='page-sub-title'> <b> 4. Accuracy</b></p>
          <ul>
            <li> By submitting your application, you do certify that the information provided by you is
              true and correct.</li>
          </ul>
          <p className='page-sub-title'> <b>5. Limitations of use</b></p>
          <ul>
            <li>
              You may not use this site for any purpose other than the intended purpose.
            </li>
          </ul>

          <p className='page-sub-title'> <b> 6. Application Submitting Terms</b></p>
          <ul className='sub-order-list'>
            <li> You must enter the passport number correctly</li>
            <li>You must enter nationality correctly as appears in the travel document </li>
            <li> You must enter your date of birth correctly</li>
            <li> You must recheck / crosscheck above information to ascertain that they have been entered correctly.
              You are required to pay for the visa again on arrival in case of any inconstancy in above particulars.</li>
            <li> You must make sure that the same travel document which was used for applying  visa
              is used for entering into Sri Lanka. In the event you produce a travel document other than the
              one used for applying  visa, you are required to pay again on arrival for the visa.</li>
          </ul>
          <p className='page-sub-title'> <b>7. On pressing &quot;Pay Now&quot;</b></p>


          <p>  The application will be redirected to Payment Gateway to pay the visa
            fee and will be outside the control of Visa  Application. The responsibility of security of
            transaction process and details on payment page will be of Payment gateway. Bank Payment
            Gateway accepts both OTP (One Time Password) and non-OTP transactions.
            In case of any issue, please contact your Bank. Please note that your application for e-Visa will not
            be submitted without fee payment. It should be done prior to 4 days of Journey date.</p>
        </div>

          </Col>
        </Row>
      </Container>
      <Disclaimer />

    
    </>
  )
}

export default TermAndCondition