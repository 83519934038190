import React from "react";
import Card from "react-bootstrap/Card";
import { Button, Container, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
import "./page.css";

const Contact = () => {
  return (
    <>
      <Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">
            <div className="page-title">
              <h2>Contact Us</h2>
            </div>
            <div className="page-main-container">
              <p className="page-sub-title">
                {" "}
                <b>Any question or remarks? Just write us a message!</b>
              </p>
              <div className="contact-page">
                <Card style={{ width: "900px" }} className="border_colo">
                  <Card.Header
                    style={{ textAlign: "center", fontWeight: "600" }}
                  >
                    {" "}
                    Contact
                  </Card.Header>
                  <p style={{ textAlign: "center", margin: "20px" }}>
                    {" "}
                    Got questions or need assistance? We’re here to help! Reach
                    out to us at{" "}
                    <a href="mailto:info@esrilankaeta.com">
                      {" "}
                      <b>info@esrilankaeta.com</b>
                    </a>
                    Our team is available 24*7. We look forward to connecting
                    with you!
                  </p>
                  <Card.Body className="contact_us">
                    <Row className="justify-content-md-center m-2">
                      <Col sm={3}>
                        <Form.Label htmlFor="basic-url">Name </Form.Label>
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          aria-label="surname "
                          aria-describedby="basic-addon1"
                          name="surname"
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center m-2">
                      <Col sm={3}>
                        <Form.Label htmlFor="basic-url">Email </Form.Label>
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          aria-label="surname "
                          aria-describedby="basic-addon1"
                          name="surname"
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center m-2">
                      <Col sm={3}>
                        <Form.Label htmlFor="basic-url">
                          write your query{" "}
                        </Form.Label>
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          aria-label="surname "
                           as="textarea"
                          aria-describedby="basic-addon1"
                          name="surname"
                        />
                      </Col>
                    </Row>
                    <Button>  Submit </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Disclaimer />
    </>
  );
};

export default Contact;
