import React from 'react'
import '../home/container.css'
function Disclaimer() {
  return (
    <div>
            <div className="disclaimer">
            <p>
               <b style={{ fontSize: "large" }}>Disclaimer :- </b><br />
               <b> www.esrilankaeta.com </b> is a professional travel agency that aims to help individuals and companies complete the necessary travel authorization requests for short-term stays. The visa fee includes expert's assistance (service fee and visa fee).
            </p>
         </div>
      
    </div>
  )
}

export default Disclaimer
