import { React } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
import "../App.css"

const Documents = () => {
  return (

    <>  
    <Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">
          <div className="centerCol">
      <div className="welcomeText">
        <h2>Documents Requirements</h2>
        <p>
          {" "}
          <b> www.esrilankaeta.com</b> is a commercial website. We are not the
          Embassy/Consulate/High Commission or the representative of any
          Government Department of Sri Lanka. If you apply through<b> www.esrilankaeta.com</b>,
          we will consult, support all required documents for your ETA Visa
          applications and you will get your ETA visa update frequently and get
          ETA result from us. To apply e-Visa under our processing, you will be
          charged service fee and Sri Lankan Government Fee. Besides that, you
          can apply in person at Sri Lankan Missions or official Sri Lankan
          Government Website. Our fee will be higher than you apply directly on
          Sri Lanka Government Website or at Sri Lanka Missions in your country.
          If you prefer a non-guided service, you can visit the Sri Lanka
          Government website.
        </p>
      </div>
    </div>  
          </Col>
        </Row>
      </Container>
      <Disclaimer />


    </>

  );
};

export default Documents;
