import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import { useFormik as useFormLk } from "formik";
import { api, uploadFile } from "../utils/api";
import { useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  CountryName,
  TouristOptions,
  TouristVisAndTime,
  BusinessVisaAndTime,
} from "../Data/Data";
import Table from "react-bootstrap/Table";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./apply.css";

function ApplyOne() {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isBusinessVisa, setBusinessVisa] = useState("");
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState({
    passport_bio_page: "",
  });
  const [touristOptions] = useState(TouristOptions);
  const [businessOptions] = useState([
    "PARTICIPATE IN BUSINESS MEETINGS AND NEGOTIATIONS",
    "Short term training programs",
  ]);

  const [touristVisaTime] = useState(TouristVisAndTime);
  const [businessVisaTime] = useState(BusinessVisaAndTime);

  const handleCheckboxChange1 = (e) => {
    setIsChecked1(e.target.checked);
  };

  const handleVisaSelectChange = (event) => {
    const { value } = event.target;
    formLk.handleChange(event);
    setBusinessVisa(value === "BUSINESS VISA");
  };

  const [child, setChild] = useState({
    child_surnane: "",
    child_givenName: "",
    child_date_of_birth: "",
    child_gender: "",
    child_relationship: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const formLk = useFormLk({
    initialValues: {
      visaType: "",
      PassportType: "",
      surname: "",
      other_name: "",
      title: "",
      date_of_birth: "",
      gender: "",
      Country_of_birth: "",
      nationality: "",
      Occupation: "",
      passport_number: "",
      passport_issued_date: "",
      passport_expiry_date: "",
      days_14_days_before_this_travel: "",
      visa_required_days: "",
      intended_arrival_date: "",
      purpose_of_visit: "",
      children: [],

      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      postal_code: "",
      Country_or_region: "",
      email: "",
      mobile_number: "",
      address_in_srilanka: "",

      applicant_company_name: "",
      applicant_company_add: "",
      applicant_company_city: "",
      applicant_company_state: "",
      applicant_company_zipcode: "",
      applicant_company_region: "",
      applicant_company_email: "",

      Sri_Lankan_Company_name: "",
      Sri_Lankan_Company_address: "",
      Sri_Lankan_Company_city: "",
      Sri_Lankan_Company_address: "",
      Sri_Lankan_Company_state: "",
      Sri_Lankan_Company_zipcode: "",
      Sri_Lankan_Company_telehone: "",

      valid_residence_visa: "",
      currently_in_sri_lanka: "",
      multiple_entry_visa: "",

      passport_bio_page: "",

      ip_address: "",
    },
    onSubmit: (values) => {
      if (!id) submitApplication({ ...values, ...document });
      else updateApplication({ ...values, ...document });
    },
  });

  const submitApplication = async (values) => {
    try {
      let response = await api.post(
        "/api/srilanka/create-application",
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data && response.data.success) {
        // alert(response.data.message);
        toast.success(response.data.message);

        navigate(`/preview/${response.data.data.sku_id}`);
      } else {
        alert("something went wrong");
      }
    } catch (error) {
      console.log("🚀 ~ submitApplication ~ error:", error);
      alert("something went wrong");
    }
  };

  const updateApplication = async (values) => {
    try {
      let response = await api.put(
        `/api/srilanka/update-application/${id}`,
        values
      );
      if (response.data && response.data.status) {
        // alert(response.data.message);
        toast.success(response.data.message);
        navigate(`/preview/${response.data.data.sku_id}`);
      } else {
        alert(response.data.message || "something went wrong");
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong");
    }
  };

  const fetchApplicationDetail = useCallback(async () => {
    if (!id) return;
    try {
      const response = await api.get(`/api/srilanka/View-application/${id}`);
      if (response.data && response.data.status) {
        let data = response.data.data;
        formLk.setValues({
          visaType: data.visaType,
          PassportType: data.PassportType,
          surname: data.surname,
          other_name: data.other_name,
          title: data.title,
          date_of_birth: data.date_of_birth,
          gender: data.gender,
          Country_of_birth: data.Country_of_birth,
          nationality: data.nationality,
          Occupation: data.Occupation,
          passport_number: data.passport_number,
          passport_issued_date: data.passport_issued_date,
          passport_expiry_date: data.passport_expiry_date,
          days_14_days_before_this_travel: data.days_14_days_before_this_travel,
          visa_required_days: data.visa_required_days,
          intended_arrival_date: data.intended_arrival_date,
          purpose_of_visit: data.purpose_of_visit, // Mapped to data.purpose_of_visit
          children: data.children || [],

          address_line1: data.address_line1,
          address_line2: data.address_line2,
          city: data.city,
          state: data.state,
          postal_code: data.postal_code,
          Country_or_region: data.country_of_residence, // Mapped to data.country_of_residence
          email: data.email,
          mobile_number: data.mobile_number,
          address_in_srilanka: data.address_in_srilanka,

          applicant_company_name: data.applicant_company_name,
          applicant_company_add: data.applicant_company_add,
          applicant_company_city: data.applicant_company_city,
          applicant_company_state: data.applicant_company_state,
          applicant_company_zipcode: data.applicant_company_zipcode,
          applicant_company_region: data.applicant_company_region,
          applicant_company_email: data.applicant_company_email,

          Sri_Lankan_Company_name: data.Sri_Lankan_Company_name,
          Sri_Lankan_Company_address: data.Sri_Lankan_Company_address,
          Sri_Lankan_Company_city: data.Sri_Lankan_Company_city,
          Sri_Lankan_Company_state: data.Sri_Lankan_Company_state,
          Sri_Lankan_Company_zipcode: data.Sri_Lankan_Company_zipcode,
          Sri_Lankan_Company_telephone: data.Sri_Lankan_Company_telehone,

          valid_residence_visa: data.valid_residence_visa,
          currently_in_sri_lanka: data.currently_in_sri_lanka,
          multiple_entry_visa: data.multiple_entry_visa,

          passport_bio_page: data.passport_bio_page,

          ip_address: data.ip_address,
        });
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong");
      navigate("/");
    }
  }, [id]);

  useEffect(() => {
    fetchApplicationDetail();
  }, [fetchApplicationDetail]);

  // const imageHandle = (e) => {
  //   const { name, files } = e.target;
  //   if (!files) return;
  //   let formData = new FormData();
  //   formData.append("image", files[0]);
  //   uploadFile({ endPoint: "/api/upload", formData })
  //     .then((res) => {
  //       console.log(`${name} uploaded successfully`, res.file.filename);
  //       setDocument((prevImages) => ({
  //         ...prevImages,
  //         [name]: res.file.filename,
  //       }));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const imageHandle = (e) => {
    const { name, files } = e.target;
    if (!files) return;

    let formData = new FormData();
    formData.append("image", files[0]);

    setLoading(true); // Start loader when upload starts
    uploadFile({ endPoint: "/api/upload", formData })
      .then((res) => {
        console.log(`${name} uploaded successfully`, res.file.filename);
        setDocument((prevImages) => ({
          ...prevImages,
          [name]: res.file.filename,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false); // Stop loader after upload completes or fails
      });
  };

  const handleChildChange = (e) => {
    const { name, value } = e.target;
    setChild((prevChild) => ({
      ...prevChild,
      [name]: value,
    }));
  };

  const handleAddChild = () => {
    formLk.setValues((prevValues) => ({
      ...prevValues,
      children: [...prevValues.children, child], // Add child to children array
    }));
    setChild({
      child_surnane: "",
      child_givenName: "",
      child_date_of_birth: "",
      child_gender: "",
      child_relationship: "",
    });
  };

  const handleRemoveChild = (index) => {
    formLk.setValues((prevValues) => ({
      ...prevValues,
      children: prevValues.children.filter((_, i) => i !== index),
    }));
  };

  return (
    <form onSubmit={formLk.handleSubmit}>
      <div className="gsrilanka">
        <div className="centerCol">
          <div className="welcomeText">
            <Container className="pagesa4">
              <p className="red">
                {" "}
                All information should be entered as per the applicant's
                passport
              </p>

              {/* form start =----------------------------- */}

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Visa Type{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="visaType"
                    value={formLk.values.visaType}
                    onChange={handleVisaSelectChange}
                  >
                    <option> Select Visa Type </option>
                    <option value="TOURIST VISA">TOURIST VISA</option>
                    <option value="BUSINESS VISA"> BUSINESS VISA</option>
                  </Form.Select>
                </Col>
              </Row>
{/* 
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Passport Type{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="PassportType"
                    value={formLk.values.PassportType}
                    onChange={formLk.handleChange}
                  >
                    <option> Select Passport Type </option>
                    <option value="ORDINARY PASSPORT">ORDINARY PASSPORT</option>
                  </Form.Select>
                </Col>
              </Row> */}

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Surname/Family Name{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="surname "
                    aria-describedby="basic-addon1"
                    name="surname"
                    value={formLk.values.surname}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Other/Given Names{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="Last Name "
                    name="other_name"
                    aria-describedby="basic-addon1"
                    value={formLk.values.other_name}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Title{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="title"
                    value={formLk.values.title}
                    onChange={formLk.handleChange}
                  >
                    <option> Select Title </option>
                    <option value="DR">DR</option>
                    <option value="MASTER">MASTER</option>
                    <option value="MISS">MISS</option>
                    <option value="MR">MR</option>
                    <option value="MRS">MRS</option>
                    <option value="MS">MS</option>
                    <option value="REV">REV</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Date of Birth{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="ggg"
                    type="date"
                    aria-describedby="basic-addon1"
                    name="date_of_birth"
                    value={formLk.values.date_of_birth}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Gender{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="gender"
                    value={formLk.values.gender}
                    onChange={formLk.handleChange}
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    {" "}
                    Country or Region of Birth{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="Country_of_birth"
                    value={formLk.values.Country_of_birth}
                    onChange={formLk.handleChange}
                  >
                    {CountryName.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Nationality{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="nationality"
                    value={formLk.values.nationality}
                    onChange={formLk.handleChange}
                  >
                    {CountryName.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    {" "}
                    Email{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="email No."
                    name="email"
                    aria-describedby="basic-addon1"
                    value={formLk.values.email}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">Occupation </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="Last Name "
                    name="Occupation"
                    aria-describedby="basic-addon1"
                    value={formLk.values.Occupation}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Passport Number{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="Whatsapp No."
                    name="passport_number"
                    aria-describedby="basic-addon1"
                    value={formLk.values.passport_number}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Passport Issued Date{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="ggg"
                    type="date"
                    aria-describedby="basic-addon1"
                    name="passport_issued_date"
                    value={formLk.values.passport_issued_date}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Passport Expiry Date{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="ggg"
                    type="date"
                    aria-describedby="basic-addon1"
                    name="passport_expiry_date"
                    value={formLk.values.passport_expiry_date}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <div className="formvisa_apply">
                Child information in parent's passport
              </div>
              <p>
                {" "}
                Children under 12 years of age possessing separate passport(s)
                should submit individual application(s)
              </p>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Surname/Family Name
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="surname"
                    aria-describedby="basic-addon1"
                    name="child_surnane"
                    value={child.child_surnane}
                    onChange={handleChildChange} // Change handler for child form
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Other/Given Names
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="other_name"
                    name="child_givenName"
                    aria-describedby="basic-addon1"
                    value={child.child_givenName}
                    onChange={handleChildChange} // Change handler for child form
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Date of Birth
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="date"
                    aria-describedby="basic-addon1"
                    name="child_date_of_birth"
                    value={child.child_date_of_birth}
                    onChange={handleChildChange} // Change handler for child form
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Gender
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="child_gender"
                    value={child.child_gender}
                    onChange={handleChildChange} // Change handler for child form
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Relationship
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="child_relationship"
                    value={child.child_relationship}
                    onChange={handleChildChange} // Change handler for child form
                  >
                    <option>Select Relationship</option>
                    <option value="Child">Child</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}></Col>
                <Col sm={4}>
                  <Button onClick={handleAddChild}>Add Child</Button>
                </Col>
              </Row>

              {formLk.values.children.length > 0 && (
                <div style={{ marginTop: "20px" }}>
                  {formLk.values.children.map((child, index) => (
                    <div
                      key={index}
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Table hover>
                        <thead>
                          <tr>
                            <th> Children</th>
                            <th>Surname</th>
                            <th>Given Names</th>
                            <th>Date of Birth</th>
                            <th>Gender</th>
                            <th>RelationShip</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{child.child_surnane}</td>
                            <td>{child.child_givenName}</td>
                            <td>{child.child_date_of_birth}</td>
                            <td>{child.child_gender}</td>
                            <td>{child.child_relationship}</td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => handleRemoveChild(index)} // Remove child by index
                              >
                                Remove
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </div>
              )}

              <div className="formvisa_apply">Travel Information</div>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Where you have been during last 14 days before this travel{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Select
                    aria-label="Default Select example"
                    name="days_14_days_before_this_travel"
                    value={formLk.values.days_14_days_before_this_travel}
                    onChange={formLk.handleChange}
                  >
                    {CountryName.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>

              {isBusinessVisa ? (
                ""
              ) : (
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">
                      {" "}
                      Visa Required Days{" "}
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {" "}
                        *
                      </span>
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Select
                      aria-label="Default Select example"
                      name="visa_required_days"
                      value={formLk.values.visa_required_days}
                      onChange={formLk.handleChange}
                    >
                      <option>Select</option>
                      {isBusinessVisa
                        ? Object.entries(businessVisaTime).map(
                            ([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            )
                          )
                        : Object.entries(touristVisaTime).map(
                            ([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            )
                          )}
                    </Form.Select>
                  </Col>
                </Row>
              )}

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    Intended Arrival Date{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    aria-label="ggg"
                    type="date"
                    aria-describedby="basic-addon1"
                    name="intended_arrival_date"
                    value={formLk.values.intended_arrival_date}
                    onChange={formLk.handleChange}
                  />
                </Col>
              </Row>

              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    {" "}
                    Purpose of Visit{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  {isBusinessVisa ? (
                    <>
                      <Form.Select
                        aria-label="Default Select example"
                        name="purpose_of_visit"
                        value={formLk.values.purpose_of_visit}
                        onChange={formLk.handleChange}
                      >
                        <option>Select Purpose</option>
                        {businessOptions.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                    </>
                  ) : (
                    <>
                      <Form.Select
                        aria-label="Default Select example"
                        name="purpose_of_visit"
                        value={formLk.values.purpose_of_visit}
                        onChange={formLk.handleChange}
                      >
                        <option>Select Purpose</option>
                        {touristOptions.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                    </>
                  )}
                </Col>
              </Row>

              {isBusinessVisa ? (
                <div>
                  {" "}
                  <div className="formvisa_apply">
                    {" "}
                    Contact Details of the Applicant's Company{" "}
                  </div>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Company Name{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="applicant_company_name"
                        aria-describedby="basic-addon1"
                        value={formLk.values.applicant_company_name}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Address{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="applicant_company_add"
                        aria-describedby="basic-addon1"
                        value={formLk.values.applicant_company_add}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        City{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="applicant_company_city"
                        aria-describedby="basic-addon1"
                        value={formLk.values.applicant_company_city}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        State{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="applicant_company_state"
                        aria-describedby="basic-addon1"
                        value={formLk.values.applicant_company_state}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Zip/Postal Code{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="applicant_company_zipcode"
                        aria-describedby="basic-addon1"
                        value={formLk.values.applicant_company_zipcode}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Country or Region{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Select
                        aria-label="Default Select example"
                        name="applicant_company_region"
                        value={formLk.values.applicant_company_region}
                        onChange={formLk.handleChange}
                      >
                        {CountryName.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Email address{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="applicant_company_email"
                        aria-describedby="basic-addon1"
                        value={formLk.values.applicant_company_email}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>{" "}
                  <div className="formvisa_apply">
                    {" "}
                    Contact Details of the Sri Lankan Company{" "}
                  </div>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Company Name{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="Sri_Lankan_Company_name"
                        aria-describedby="basic-addon1"
                        value={formLk.values.Sri_Lankan_Company_name}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Address{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="Sri_Lankan_Company_address"
                        aria-describedby="basic-addon1"
                        value={formLk.values.Sri_Lankan_Company_address}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        City{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="Sri_Lankan_Company_city"
                        aria-describedby="basic-addon1"
                        value={formLk.values.Sri_Lankan_Company_city}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        State{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="Sri_Lankan_Company_state"
                        aria-describedby="basic-addon1"
                        value={formLk.values.Sri_Lankan_Company_state}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Zip/Postal Code{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="Sri_Lankan_Company_zipcode"
                        aria-describedby="basic-addon1"
                        value={formLk.values.Sri_Lankan_Company_zipcode}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Telephone Number{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      {/* <Form.Select
                        aria-label="Default Select example"
                        name="Sri_Lankan_Company_telehone"
                        value={formLk.values.Sri_Lankan_Company_telehone}
                        onChange={formLk.handleChange}
                      >
                        {CountryName.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select> */}

                      <Form.Control
                        aria-label="Whatsapp No."
                        name="Sri_Lankan_Company_telehone"
                        aria-describedby="basic-addon1"
                        value={formLk.values.Sri_Lankan_Company_telehone}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  {" "}
                  <div className="formvisa_apply">Contact Details</div>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Address Line 1 in Sri Lanka{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="address_line1"
                        aria-describedby="basic-addon1"
                        value={formLk.values.address_line1}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Address Line 2 in Sri Lanka{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="address_line2"
                        aria-describedby="basic-addon1"
                        value={formLk.values.address_line2}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        City{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="city"
                        aria-describedby="basic-addon1"
                        value={formLk.values.city}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        State{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="state"
                        aria-describedby="basic-addon1"
                        value={formLk.values.state}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Zip/Postal Code{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="postal_code"
                        aria-describedby="basic-addon1"
                        value={formLk.values.postal_code}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Country or Region{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Select
                        aria-label="Default Select example"
                        name="Country_or_region"
                        value={formLk.values.Country_or_region}
                        onChange={formLk.handleChange}
                      >
                        {CountryName.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Mobile/ Cell Phone Number{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="mobile_number"
                        aria-describedby="basic-addon1"
                        value={formLk.values.mobile_number}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Address in Sri Lanka{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        aria-label="Whatsapp No."
                        name="address_in_srilanka"
                        aria-describedby="basic-addon1"
                        value={formLk.values.address_in_srilanka}
                        onChange={formLk.handleChange}
                      />
                    </Col>
                  </Row>{" "}
                </div>
              )}

              <div className="formvisa_apply">Declaration</div>

              <Row className="justify-content-md-center m-2">
                <Col sm={7}>
                  <Form.Label htmlFor="basic-url">
                    {" "}
                    1 . Do you have a valid residence visa to Sri Lanka?{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>

                <Col sm={4}>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      id="yes"
                      label="Yes"
                      name="valid_residence_visa"
                      value="Yes"
                      checked={formLk.values.valid_residence_visa === "Yes"}
                      onChange={formLk.handleChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      id="no"
                      label="No"
                      name="valid_residence_visa"
                      value="No"
                      checked={formLk.values.valid_residence_visa === "No"}
                      onChange={formLk.handleChange}
                      defaultChecked // Ensures 'No' is selected by default
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={7}>
                  <Form.Label htmlFor="basic-url">
                    {" "}
                    2 . Do you have a multiple entry visa to Sri Lanka?{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      id="yes"
                      label="Yes"
                      name="multiple_entry_visa"
                      value="Yes"
                      checked={formLk.values.multiple_entry_visa === "Yes"}
                      onChange={formLk.handleChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      id="no"
                      label="No"
                      name="multiple_entry_visa"
                      value="No"
                      checked={formLk.values.multiple_entry_visa === "No"}
                      onChange={formLk.handleChange}
                      defaultChecked // Ensures 'No' is selected by default
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={7}>
                  <Form.Label htmlFor="basic-url">
                    {" "}
                    3 . Are you currently in Sri Lanka with a valid ETA or
                    obtained an extension of visa?{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      id="yes"
                      label="Yes"
                      name="currently_in_sri_lanka"
                      value="Yes"
                      checked={formLk.values.currently_in_sri_lanka === "Yes"}
                      onChange={formLk.handleChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      id="no"
                      label="No"
                      name="currently_in_sri_lanka"
                      value="No"
                      checked={formLk.values.currently_in_sri_lanka === "No"}
                      onChange={formLk.handleChange}
                      defaultChecked // Ensures 'No' is selected by default
                    />
                  </div>
                </Col>
              </Row>

              <div className="formvisa_apply">Required Document</div>
              <Row className="justify-content-md-center m-2">
                <Col sm={5}>
                  <Form.Label htmlFor="basic-url">
                    {" "}
                    Passport Copy{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </span>
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Control
                    type="file"
                    aria-label=""
                    name="passport_bio_page"
                    onChange={imageHandle}
                  />
                </Col>
              </Row>

              {loading && (
                <div style={{ fontSize: "1.2em", color: "#555" }}>
                  Uploading...
                </div>
              )}
              <p className="red">
                {" "}
                Please consider information you entered before confirm
              </p>
              <Row className="justify-content-md-center m-2 etavisatype">
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="checkbox"
                    className="kkkmmmsss"
                    onChange={handleCheckboxChange1}
                    checked={isChecked1}
                    label=" I would like to confirm the above information is correct. "
                  />
                </Form>
              </Row>
              <Row className="justify-content-md-center m-2">
                <Col sm={4} className="center-button">
                  <Button type="submit" disabled={!isChecked1} style={{width:"400px"}}>
                    {" "}
                    {id ? "Update and Next" : "Submit"}{" "}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <ToastContainer />
      </div>
    </form>
  );
}

export default ApplyOne;
