import { React } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
import "../App.css"

const About = () => {
  return (
    <>

      <Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">

          <div className='page-title' >
          <h2>About us  </h2>
        </div >
        <div className="page-main-container">
          <p className="page-sub-title"> <b> Welcome to www.esrilankaeta.com </b></p>
          <p> At <b> www.esrilankaeta.com</b>, we specialize in providing seamless and efficient eVisa services for
            travelers wishing to explore the beautiful island of Sri Lanka. Our mission is to simplify
            the visa application process, ensuring that your journey to Sri Lanka is smooth and
            hassle-free.</p>
          <p className='page-sub-title'> <b> Who We Are</b></p>
          <p> Founded by a team of travel enthusiasts and professionals with extensive experience in
            the travel and tourism industry, <b> www.esrilankaeta.com</b> is dedicated to offering personalized
            services to meet the unique needs of each traveler. Our expertise and commitment to
            excellence set us apart as a trusted partner in your travel planning..</p>
          <p className='page-sub-title'> <b> Why Choose Us</b></p>
          <ul className="sub-order-list">
            <li> <b> Expertise:</b> With years of experience in the travel industry, we possess in-depth
              knowledge of the Sri Lankan visa application process. </li>
            <li> <b> Efficiency:</b> Our streamlined process and attention to detail ensure a quick and
              hassle-free application experience. </li>
            <li> <b> Customer Satisfaction:</b> We prioritize our clients&#39; needs and are committed to
              providing exceptional service and support.</li>
            <li> <b> Reliability: </b> Trust us to handle your application with the utmost care and
              professionalism.</li>
          </ul>
          <p className='page-sub-title'> <b> Our Vision</b></p>
          <p> Our vision is to become the leading eVisa consultant for Sri Lanka, known for our
            reliability, efficiency, and outstanding customer service. We strive to make travel
            accessible and enjoyable for everyone by removing the barriers associated with visa
            applications.</p>
          <p> Thank you for choosing <b> www.esrilankaeta.com</b>. We look forward to helping you embark on your
            Sri Lankan adventure!</p>
        </div> 
        
          </Col>
        </Row>
      </Container>
      <Disclaimer />

    </>
  );
};

export default About;
