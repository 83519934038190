
import { React } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
import "../App.css"
import "./page.css"

function EligibleCountry() {
  return (

    <> 
      <Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">

          <div className='eligible-country-list'>
      <h2 id="country-list" > Eligible Countries for Sri Lanka eVisa </h2>
      <div className="country-list-container">
        <p> The <b> Sri Lanka eVisa </b> is available to citizens of many countries around the world. Travelers from
          the following countries are eligible to apply for an eVisa to visit Sri Lanka for tourism or business
          purposes:</p>
        <div className="country-list-name">
          <table>
            <tbody className='table-body'>
              <tr>
                <td>1. Afghanistan</td>
                <td>2. Albania</td>
                <td>3. Algeria</td>
              </tr>
              <tr>
                <td>4. American Samoa</td>
                <td>5. Andorra</td>
                <td>6. Angola</td>
              </tr>
              <tr>
                <td>7. Antarctica</td>
                <td>8. Antigua and Barbuda</td>
                <td>9. Argentina</td>
              </tr>
              <tr>
                <td>10. Armenia</td>
                <td>11. Aruba</td>
                <td>12. Australia</td>
              </tr>
              <tr>
                <td>13. Austria</td>
                <td>14. Azerbaijan</td>
                <td>15. Bahamas</td>
              </tr>
              <tr>
                <td>16. Bahrain</td>
                <td>17. Bangladesh</td>
                <td>18. Barbados</td>
              </tr>
              <tr>
                <td>19. Belarus</td>
                <td>20. Belgium</td>
                <td>21. Belize</td>
              </tr>
              <tr>
                <td>22. Benin</td>
                <td>23. Bermuda</td>
                <td>24. Bhutan</td>
              </tr>
              <tr>
                <td>25. Bolivia</td>
                <td>26. Botswana</td>
                <td>27. Brazil</td>
              </tr>
              <tr>
                <td>28. British Virgin Islands</td>
                <td>29. Brunei</td>
                <td>30. Bulgaria</td>
              </tr>
              <tr>
                <td>31. Burkina Faso</td>
                <td>32. Burundi</td>
                <td>33. Cape Verde</td>
              </tr>
              <tr>
                <td>34. Cayman Islands</td>
                <td>35. Central African Republic</td>
                <td>36. Chad</td>
              </tr>
              <tr>
                <td>37. Chile</td>
                <td>38. China</td>
                <td>39. Christmas Island</td>
              </tr>
              <tr>
                <td>40. Cocos Islands</td>
                <td>41. Colombia</td>
                <td>42. Comoros</td>
              </tr>
              <tr>
                <td>43. Cook Islands</td>
                <td>44. Costa Rica</td>
                <td>45. Côte d'Ivoire</td>
              </tr>
              <tr>
                <td>46. Croatia</td>
                <td>47. Cuba</td>
                <td>48. Curaçao</td>
              </tr>
              <tr>
                <td>49. Cyprus</td>
                <td>50. Czech Republic</td>
                <td>51. Denmark</td>
              </tr>
              <tr>
                <td>52. Djibouti</td>
                <td>53. Dominica</td>
                <td>54. Dominican Republic</td>
              </tr>
              <tr>
                <td>55. Ecuador</td>
                <td>56. Egypt</td>
                <td>57. El Salvador</td>
              </tr>
              <tr>
                <td>58. Equatorial Guinea</td>
                <td>59. Eritrea</td>
                <td>60. Estonia</td>
              </tr>
              <tr>
                <td>61. Eswatini</td>
                <td>62. Ethiopia</td>
                <td>63. Falkland Islands</td>
              </tr>
              <tr>
                <td>64. Faroe Islands</td>
                <td>65. Fiji</td>
                <td>66. Finland</td>
              </tr>
              <tr>
                <td>67. France</td>
                <td>68. France Metropolitan</td>
                <td>69. French Guiana</td>
              </tr>
              <tr>
                <td>70. French Polynesia</td>
                <td>71. Gabon</td>
                <td>72. Gambia</td>
              </tr>
              <tr>
                <td>73. Georgia</td>
                <td>74. Germany</td>
                <td>75. Gibraltar</td>
              </tr>
              <tr>
                <td>76. Greece</td>
                <td>77. Greenland</td>
                <td>78. Grenada</td>
              </tr>
              <tr>
                <td>79. Guadeloupe</td>
                <td>80. Guam</td>
                <td>81. Guatemala</td>
              </tr>
              <tr>
                <td>82. Guernsey</td>
                <td>83. Guinea</td>
                <td>84. Guinea-Bissau</td>
              </tr>
              <tr>
                <td>85. Guyana</td>
                <td>86. Haiti</td>
                <td>87. Honduras</td>
              </tr>
              <tr>
                <td>88. Hong Kong</td>
                <td>89. Hungary</td>
                <td>90. Iceland</td>
              </tr>
              <tr>
                <td>91. India</td>
                <td>92. Indonesia</td>
                <td>93. Iran</td>
              </tr>
              <tr>
                <td>94. Iraq</td>
                <td>95. Ireland</td>
                <td>96. Isle of Man</td>
              </tr>
              <tr>
                <td>97. Israel</td>
                <td>98. Italy</td>
                <td>99. Jamaica</td>
              </tr>
              <tr>
                <td>100. Japan</td>
                <td>101. Jersey</td>
                <td>102. Jordan</td>
              </tr>
              <tr>
                <td>103. Kazakhstan</td>
                <td>104. Kenya</td>
                <td>105. Kiribati</td>
              </tr>
              <tr>
                <td>106. Kosovo</td>
                <td>107. Kuwait</td>
                <td>108. Kyrgyzstan</td>
              </tr>
              <tr>
                <td>109. Laos</td>
                <td>110. Latvia</td>
                <td>111. Lebanon</td>
              </tr>
              <tr>
                <td>112. Lesotho</td>
                <td>113. Liberia</td>
                <td>114. Libya</td>
              </tr>
              <tr>
                <td>115. Liechtenstein</td>
                <td>116. Lithuania</td>
                <td>117. Luxembourg</td>
              </tr>
              <tr>
                <td>118. Macao</td>
                <td>119. Macedonia</td>
                <td>120. Madagascar</td>
              </tr>
              <tr>
                <td>121. Malawi</td>
                <td>122. Malaysia</td>
                <td>123. Maldives</td>
              </tr>
              <tr>
                <td>124. Mali</td>
                <td>125. Malta</td>
                <td>126. Marshall Islands</td>
              </tr>
              <tr>
                <td>127. Martinique</td>
                <td>128. Mauritania</td>
                <td>129. Mauritius</td>
              </tr>
              <tr>
                <td>130. Mayotte</td>
                <td>131. Mexico</td>
                <td>132. Micronesia</td>
              </tr>
              <tr>
                <td>133. Moldova</td>
                <td>134. Monaco</td>
                <td>135. Mongolia</td>
              </tr>
              <tr>
                <td>136. Montenegro</td>
                <td>137. Montserrat</td>
                <td>138. Morocco</td>
              </tr>
              <tr>
                <td>139. Mozambique</td>
                <td>140. Myanmar</td>
                <td>141. Namibia</td>
              </tr>
              <tr>
                <td>142. Nauru</td>
                <td>143. Nepal</td>
                <td>144. Netherlands</td>
              </tr>
              <tr>
                <td>145. Netherlands Antilles</td>
                <td>146. New Caledonia</td>
                <td>147. New Zealand</td>
              </tr>
              <tr>
                <td>148. Nicaragua</td>
                <td>149. Niger</td>
                <td>150. Nigeria</td>
              </tr>
              <tr>
                <td>151. Niue</td>
                <td>152. Norfolk Island</td>
                <td>153. North Korea</td>
              </tr>
              <tr>
                <td>154. Northern Mariana Islands</td>
                <td>155. Norway</td>
                <td>156. Oman</td>
              </tr>
              <tr>
                <td>157. Pakistan</td>
                <td>158. Palau</td>
                <td>159. Panama</td>
              </tr>
              <tr>
                <td>160. Papua New Guinea</td>
                <td>161. Paraguay</td>
                <td>162. Peru</td>
              </tr>
              <tr>
                <td>163. Philippines</td>
                <td>164. Pitcairn Islands</td>
                <td>165. Poland</td>
              </tr>
              <tr>
                <td>166. Portugal</td>
                <td>167. Puerto Rico</td>
                <td>168. Qatar</td>
              </tr>
              <tr>
                <td>169. Republic of the Congo</td>
                <td>170. Réunion</td>
                <td>171. Romania</td>
              </tr>
              <tr>
                <td>172. Russia</td>
                <td>173. Rwanda</td>
                <td>174. Saint Barthélemy</td>
              </tr>
              <tr>
                <td>175. Saint Helena</td>
                <td>176. Saint Lucia</td>
                <td>177. Saint Martin</td>
              </tr>
              <tr>
                <td>178. Samoa</td>
                <td>179. San Marino</td>
                <td>180. Saudi Arabia</td>
              </tr>
              <tr>
                <td>181. Senegal</td>
                <td>182. Serbia</td>
                <td>183. Seychelles</td>
              </tr>
              <tr>
                <td>184. Sierra Leone</td>
                <td>185. Singapore</td>
                <td>186. Sint Maarten</td>
              </tr>
              <tr>
                <td>187. Slovakia</td>
                <td>188. Slovenia</td>
                <td>189. Solomon Islands</td>
              </tr>
              <tr>
                <td>190. Somalia</td>
                <td>191. South Africa</td>
                <td>192. South Georgia</td>
              </tr>
              <tr>
                <td>193. South Korea</td>
                <td>194. South Sudan</td>
                <td>195. Spain</td>
              </tr>
              <tr>
                <td>196. Sri Lanka</td>
                <td>197. Sudan</td>
                <td>198. Suriname</td>
              </tr>
              <tr>
                <td>199. Sweden</td>
                <td>200. Switzerland</td>
                <td>201. Syria</td>
              </tr>
              <tr>
                <td>202. Taiwan</td>
                <td>203. Tajikistan</td>
                <td>204. Tanzania</td>
              </tr>
              <tr>
                <td>205. Thailand</td>
                <td>206. Timor-Leste</td>
                <td>207. Togo</td>
              </tr>
              <tr>
                <td>208. Tokelau</td>
                <td>209. Tonga</td>
                <td>210. Trinidad and Tobago</td>
              </tr>
              <tr>
                <td>211. Tunisia</td>
                <td>212. Turkey</td>
                <td>213. Turkmenistan</td>
              </tr>
              <tr>
                <td>214. Tuvalu</td>
                <td>215. Uganda</td>
                <td>216. Ukraine</td>
              </tr>
              <tr>
                <td>217. United Arab Emirates</td>
                <td>218. United Kingdom</td>
                <td>219. United States</td>
              </tr>
              <tr>
                <td>220. Uruguay</td>
                <td>221. Uzbekistan</td>
                <td>222. Vanuatu</td>
              </tr>
              <tr>
                <td>223. Vatican City</td>
                <td>224. Venezuela</td>
                <td>225. Vietnam</td>
              </tr>
              <tr>
                <td>226. Virgin Islands</td>
                <td>227. Wallis and Futuna</td>
                <td>228. Western Sahara</td>
              </tr>
              <tr>
                <td>229. Yemen</td>
                <td>230. Zambia</td>
                <td>231. Zimbabwe</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

          </Col>
        </Row>
      </Container>
      <Disclaimer />
    
    </>
  
  )
}

export default EligibleCountry