import React, { useEffect, useRef } from "react";
import "./container.css"
import { Link } from "react-router-dom";
 import m1 from "../images/approved.png"
import m2 from "../images/customer-care.png"
import m3 from "../images/passport.png"
import m4 from "../images/secure-payment.png"
import m5 from "../images/verified-visa.png"
import m6 from "../images/srilanka_visa_page.png"
const paragraphs = [
  { id: 1, text: "Visitors from Cameroon, Nigeria, Ivory Coast and Ghana who intend to apply for evisa  may apply through Sri Lankan Sponsor for their evisa  from the Head office of the Department of Immigration & Emigration, Sri Lanka." },

];

function SidePage() {
  const scrollRef = useRef(null);

  // Function to auto-scroll
  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop += 1;
      }
    }, 50); // Adjust the speed of scroll

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className='side_item_one'>
        <span className='impss'> Important instructions</span>
        <div className="scroll-container" ref={scrollRef}>
      {paragraphs.map((item) => (
        <p key={item.id} className="scroll-paragraph">{item.text}</p>
      ))}
    </div>
      </div>
      <div className='side_item_two'>
      <ul >
            <li>
              <Link to="/"> &#x2022; Home</Link>
            </li> <hr/>
            <li>
              <Link to="/about" > &#x2022; About Us</Link>
            </li><hr/>
            <li>
              <Link to="/services"> &#x2022; Services</Link>
            </li> <hr/>
            <li>
              <Link to="/eligible-country"> &#x2022; Eligible Countries</Link>
            </li><hr/>
            <li>
              <Link to="/privacy-policy"> &#x2022; Privacy Policy
              </Link>
            </li><hr/>
            <li>
              <Link to="/term-and-condition"> &#x2022; Terms & Conditions</Link>
            </li><hr/>
            <li>
              <Link to="/refund-policy"> &#x2022; Refund  Policy</Link>
            </li><hr/>
         
            <li>
              <Link to="/contact"> &#x2022; Contact us</Link>
            </li> <hr/>
          </ul>

      </div>
      <div className='side_item_three'>
      <div className='m4'> <img src={m4} alt='kk'/> </div>
      <div> <img src={m5} alt='kk'/> </div>
       <div> <img src={m2} alt='kk'/> </div>
    
      </div>
      <div className='side_item_four'>
       <div> <img src={m3} alt='kk'/> </div>
       <div> <img src={m1} alt='kk'/> </div>
      


      </div>


    </>
  )
}

export default SidePage