import { React } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
import "../App.css"

function RefundPolicy() {
   return (
      <> 

<Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">
          <div className="page-title">
               <h2>Refund  and Cancellation Policy</h2>
            </div>
            <div className="page-main-container">
               <p>The Sri Lanka eVisa, or Electronic Travel Authorization (ETA), is a
                  non-refundable service, meaning that once the application is submitted
                  and payment is made, refunds are not typically issued under most circumstances.
                  Here’s a detailed overview of the refund policy:
               </p>
               <ol className='order-list'>
                  <li>
                     <b>Non-Refundable Fees</b>
                     <ul className='order-list'>
                        <li><b>Application Fee:</b> The fee paid for processing the Sri Lanka eVisa application is non-refundable. This applies whether the application is approved, denied, or canceled by the applicant.</li>
                        <li><b>No Refund for Rejected Applications:</b> If your application is rejected due to reasons such as incorrect or incomplete information, the processing fee will not be refunded. Applicants must pay the fee again if they wish to reapply with corrected information.</li>
                     </ul>
                  </li>
                  <li>
                     <b>Incorrect Information</b>
                     <ul>
                        <li>If an applicant makes an error in the application form (such as entering incorrect passport details, travel dates, or personal information) and submits it, the application cannot be corrected or canceled for a refund. Any corrections will require a new application, which will involve a new fee.</li>
                     </ul>
                  </li>
                  <li>
                     <b>Service Providers</b>
                     <ul>
                        <li>If you apply for a Sri Lanka eVisa through a third-party visa service provider, their service fees are also typically non-refundable. The policies regarding refunds may vary slightly between providers, so it’s essential to review their terms and conditions before proceeding with the application.</li>
                     </ul>
                  </li>
                  <li>
                     <b>Technical Issues and Duplicate Payments</b>
                     <ul>
                        <li> In rare cases where technical errors occur during the payment process, resulting in duplicate charges, a refund may be issued for the duplicate payment. Applicants experiencing such issues should contact the Sri Lanka ETA help desk or their payment provider with proof of the duplicate charge for resolution.</li>
                     </ul>
                  </li>
                  <li>
                     <b>Application Withdrawal or Cancellation</b>
                     <ul>
                        <li> Once the eVisa application has been submitted, it cannot be withdrawn or canceled, and the fee paid is non-refundable. This applies even if the applicant decides not to travel to Sri Lanka after the eVisa has been issued.</li>
                     </ul>
                  </li>
               </ol>
               <p className="page-sub-title">
                  <b>Conclusion</b>
               </p>
               <p>The Sri Lanka eVisa system is designed to be a straightforward and efficient process, but it is crucial to double-check all information before submission to avoid errors that could lead to additional costs. Since the processing fee is non-refundable, ensuring accuracy in your application will help avoid unnecessary expenses. Always review the terms and conditions provided by the official Sri Lankan ETA website or any third-party service provider before submitting your application.</p>
            </div>
       

          </Col>
        </Row>
      </Container>
      <Disclaimer />

     
      </>
   )
}

export default RefundPolicy