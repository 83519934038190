import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeLayout from "./layout/Home-layout";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Documents from "./pages/Documents";
import Service from "./pages/Service";
import Policies from "./pages/Policies";
import Thanks from "./apply/Thanks";
import Cancel from "./apply/Cancel";
import Preview from "./apply/Preview";
import Payment from "./apply/Payment";
import PartialPayment from "./apply/Partialpay";
import ApplyOne from "./apply/ApplyOne";
import Faq from "./pages/Faq";
import TravelUpdates from "./pages/Travel-updates";
import BlogLayout from "./layout/BlogLayout";
import EligibleCountry from "./pages/EligibleCountry";
import TermAndCondition from "./pages/TermConditions";
import RefundPolicy from "./pages/RefundPolicy";
import ContentLayout from "./home/ContentLayout";
import Tourist from "./pages/Tourist";
import Business from "./pages/Business";
import Instruction from "./pages/Instruction";
import Welcome from "./pages/Welcome";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
    <>
      <Router>
        <Header />
        {/* <HomeLayout /> */}
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="about" element={<About />} />
          <Route path="term-and-condition" element={<TermAndCondition />} />
          <Route path="contact" element={<Contact />} />
          <Route path="documents" element={<Documents />} />
          <Route path="services" element={<Service />} />
          <Route path="privacy-policy" element={<Policies />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route path="preview/:id" element={<Preview />} />
          <Route path="tourist-e-visa" element={<Tourist />} />
          <Route path="business-e-visa" element={<Business />} />
          <Route path="instructions" element={<Instruction />} />
          <Route path="faq" element={<Faq />} />
          <Route path="thanks" element={<Thanks />} />
          <Route path="payment/:id" element={<Payment />} />
          <Route path="partialpay" element={<PartialPayment />} />
          <Route path="apply" element={<ApplyOne />} />
          <Route path="cancel" element={<Cancel />} />
          <Route path="apply/:id" element={<ApplyOne />} />
          <Route path="travel-updates" element={<TravelUpdates />} />
          <Route path="eligible-country" element={<EligibleCountry />}></Route>
        </Routes>
        <Footer />
        {/* <Routes>
          <Route path="/" exact element={<HomeLayout />}>
            <Route path="/" element={<Welcome />}>
              <Route path="about" element={<About />} />
              <Route path="term-and-condition" element={<TermAndCondition />} />
              <Route path="contact" element={<Contact />} />
              <Route path="documents" element={<Documents />} />
              <Route path="services" element={<Service />} />
              <Route path="privacy-policy" element={<Policies />} />
              <Route path="refund-policy" element={<RefundPolicy />} />
              <Route path="preview/id" element={<Preview />} />
              <Route path="tourist-e-visa" element={<Tourist />} />
              <Route path="business-e-visa" element={<Business />} />
              <Route path="instructions" element={<Instruction />} />
              <Route path="faq" element={<Faq />} />
            </Route>
          </Route>
          <Route path="/" element={<BlogLayout />}>
            <Route path="thanks" element={<Thanks />} />
            <Route path="payment/:id" element={<Payment />} />
            <Route path="partialpay" element={<PartialPayment />} />
            <Route path="apply" element={<ApplyOne />} />
            <Route path="cancel" element={<Cancel />} />
            <Route path="apply/:id" element={<ApplyOne />} />
            <Route path="travel-updates" element={<TravelUpdates />} />
            <Route path='eligible-country' element={<EligibleCountry />}></Route>
          </Route>
        </Routes> */}
      </Router>
    </>
  );
}

export default App;
