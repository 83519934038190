import React from "react";
import "./component.css"
import { useNavigate } from "react-router-dom";

const importantLink = [
  { name: "Home", path: "/" },
  { name: "Privacy Policy", path: "/privacy-policy" },
  { name: "Terms Conditions", path: "/term-and-condition" },
  { name: "About Us", path: "/about" },
  { name: "Contact", path: "/contact" }
]

const Footer = () => {
  const navigate = useNavigate()
  const handleChangeRoute = (path) => {
    window.scrollTo(0, 500);
    navigate(path)
  }
  return (
    <>
      <div className="layout-footer">
        <div className="size-box">
          <div className="important-link">
            {importantLink.map((ele, idx) => (
              <div key={idx} className="important-link-data" onClick={() => handleChangeRoute(ele.path)}>{ele.name}</div>
            ))}
          </div>
          <div className="footer">
            <div> @ Copyright 2024 by esrilankaeta.com. All Rights Reserved</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
