import React from "react";
import '../App.css'
import { IoMdMenu } from "react-icons/io";
import { useState } from 'react';
import { Link } from "react-router-dom";
import logo from "../images/logo.png"
// import starImage from "../images/star.png"
import { Offcanvas, Button } from "react-bootstrap";



const Header = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <header className="page-header" >
        <div className="flex-container">
          <img src={logo} alt="#" className="logosrilanka" />
          <div className="p-group">
            <p id="name-title" className="typing-animation">Sri Lanka</p>
            <p id="title-name" className="no-gap" style={{ fontSize: "16px" }}> eVisa Portal</p>
          </div>
          {/* <img src={starImage} alt="star-image" className="star-image"></img> */}
        </div>

        <div className="navigationBox fixed-element">
          <div className="centerCol">
            <div className="menu_btn">
              <div >
                <Button variant="secondary" className="d-lg-none" onClick={toggleShow} style={{
                  cursor: 'pointer',
                  padding: '5px 10px',
                  display: "flex", justifyItems: "center"
                }} >
                  <IoMdMenu onClick={toggleShow} style={{ cursor: 'pointer' }} className="home_btn_menu" />
                </Button>

                <Offcanvas show={show} onHide={toggleShow} responsive="sm" style={{ width: '250px' }} >
                  <Offcanvas.Header className="offcanvas-header-custom" closeButton>
                    {/* <Offcanvas.Title>Menu</Offcanvas.Title> */}
                  </Offcanvas.Header>
                  <Offcanvas.Body className="canvas-design">
                    <Link to="/" onClick={toggleShow} >Home</Link><br />
                    <Link to="/about" onClick={toggleShow}>About Us</Link><br />
                    <Link to="/term-and-condition" onClick={toggleShow}>Terms &amp; Conditions</Link><br />
                    <Link to="/services" onClick={toggleShow}>Services</Link><br />
                    <Link to="/privacy-policy" onClick={toggleShow}>Privacy Policy</Link><br />
                    <Link to="/contact" onClick={toggleShow}> Contact us</Link><br />
                  
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
            <ul className="navbar_h">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/term-and-condition">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/contact"> Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </header >
    </>
  );
};

export default Header;
